/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}

body {
    line-height: 1;
}

ol, ul {
    list-style: none;
}

blockquote, q {
    quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

body {
    font-size: 100%;
    font-family: sans-serif;
    background: #e2eddc;
}

/*@font-face {*/
/*font-family: 'Ubuntu Bold';*/
/*src: url(assets/fonts/Ubuntu/Ubuntu-Bold.ttf);*/
/*}*/

/*@font-face {*/
/*font-family: 'Ubuntu';*/
/*src: url(assets/fonts/Ubuntu/Ubuntu-Regular.ttf);*/
/*}*/

/*@font-face {*/
/*font-family: 'Ubuntu Light';*/
/*src: url(assets/fonts/Ubuntu/Ubuntu-Light.ttf);*/
/*}*/

/*@font-face {*/
/*font-family: 'Montserrat Bold';*/
/*src: url(assets/fonts/Montserrat/Montserrat-Bold.ttf);*/
/*}*/

.ReactModal__Body--open {
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    overflow: auto;
    z-index: 100;
}

.ReactModal__Content {
    background-color: white;
    box-sizing: border-box;
    width: 90%;
    max-width: 70rem;
    outline: none;
    margin: 11.75rem auto;
    position: relative;
}

.TestingModals {
    padding: 0;
}

.checkoutBody {
    background: #e2eddc;
}

.notFound {
    background: #e2eddc;
}

#react-print {
    display: block !important;
}
